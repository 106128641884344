<template>
    <div class="box">
        <el-form
            ref="form"
            label-width="180px"
        >
            <el-form-item>
                <h3>{{ pageTitle }}</h3>
            </el-form-item>

            <el-form-item label="选择优惠券">
                <el-select
                    v-model="model.coupon_id"
                    multiple
                    collapse-tags
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in coupons"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="分享标题描述">
                <el-col :span="5">
                    <el-input
                        type='text'
                        min="0"
                        v-model="model.shareTitle"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="标题">
                <el-col :span="5">
                    <el-input
                        type='text'
                        min="0"
                        v-model="model.title"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="标题描述">
                <el-col :span="8">
                    <el-input
                        type='text'
                        min="0"
                        v-model="model.tDes"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="活动规则标题">
                <el-col :span="5">
                    <el-input
                        type='text'
                        min="0"
                        v-model="model.ruleName"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="活动规则描述">
                <el-col :span="8">
                    <el-input
                        type='text'
                        min="0"
                        v-model="model.ruleDes"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="图片">
                <bg
                    :avatar="model.background"
                    @on-upload-success="handleBgSuccess"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    size='medium'
                    @click="createFree"
                >保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import ShareService from '@admin/services/ShareService'
import flatry from '@admin/utils/flatry'
import upload from '@admin/components/uploaders/Uploader'
export default {
  name: 'ShreSetting',
  components: { bg: upload },
  data () {
    return {
      pageTitle: '邀请有豪礼',
      model: {
        background: ''
      },
      coupons: []
    }
  },
  async created () {
    const { data } = await flatry(ShareService.get())
    if (data) {
      this.coupons = data.data.coupon
      if (data.data.share.title) {
        this.model = data.data.share
      }
    }
  },
  methods: {
    handleBgSuccess (url) {
      this.model.background = url
    },
    async createFree () {
      console.log(this.model)
      const { data } = await flatry(ShareService.change(this.model))
      if (data) {
        this.$message.success(data.msg || '')
        this.$router.replace({ path: '/share' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
