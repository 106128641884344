import Vue from 'vue'

const StoreService = {
  get (query) {
    return Vue.http.get('share/index', query)
  },
  change (model) {
    return Vue.http.post('share/index', model)
  }
}

export default StoreService
